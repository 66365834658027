<template>
	<NuxtLink v-if="props.link" :to="props.link"
	          class="text-white-0 flex gap-3 items-center px-3 py-2 rounded-lg w-full justify-center"
	          :class="props.color + ' ' + props.classes">
		{{ props.text }}
		<img v-if="props.arrow" alt="Arrow pointing to the right" :src="arrow === 'white' ? white_arrow : black_arrow"
		     width="10" height="10">
	</NuxtLink>
	<button v-else :type="props.type" @click="props.action"
	        class="text-white-0 flex gap-3 items-center px-3 py-2 rounded-lg w-full justify-center"
	        :class="props.color + ' ' + props.classes">
		{{ props.text }}
		<img v-if="props.arrow" alt="Arrow pointing to the right" :src="arrow === 'white' ? white_arrow : black_arrow"
		     width="10" height="10">
	</button>
</template>

<script setup>
import black_arrow from '~/assets/icons/right_arrow_small.svg';
import white_arrow from '~/assets/icons/right_arrow_small_white.svg';

const props = defineProps({
	type: {
		type: String,
		default: "button",
	},
	action: {
		type: Function,
	},
	text: {
		type: String,
		required: true,
	},
	color: {
		type: String,
		default: "bg-blue-700 hover:bg-blue-800"
	},
	classes: {
		type: String,
		default: ""
	},
	arrow: {
		type: String,
		default: "white",
	},
	link: {
		type: String,
		default: null,
	}
});
</script>